<template>
  <div :class="`news-for-user ${user._id === last && 'lastSelect'} ${list}`">
    <div class="news-for-user-avatar">
      <figure class="picture" :style="`--picture:url(${user.picture})`"></figure>
    </div>
    <div class="news-for-user-info">
      <div w100>
        <p class="name" bold>
          <span>{{ user.user }}</span>
          <span class="button" @click="deleteUser">
            <iconic :class="`the_icon ${isSelected ? 'isSelected' : 'notSelected'}`" name="check_circle" />
          </span>
        </p>
        <p>{{ user.city }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "isSelected", "last", "delete", "list"],
  methods: {
    deleteUser: function() {
      if (!this.delete) return;
      this.delete(this.user);
    },
  },
};
</script>

<style lang="scss">
$avata_size: 50px;
.news-for-selected {
  .lastSelect {
    animation: ItermitColor 3s ease;
  }
}
.news-for-user {
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: $mpadding/2;
  border: 1px solid #cecece;
  &.result {
    cursor: pointer;
  }
  &:hover {
    background-color: #f1f1f1;
  }
  &-avatar {
    padding: $mpadding/2 $mpadding $mpadding/2 $mpadding;
    .picture {
      width: $avata_size;
      height: $avata_size;
      border-radius: 50%;
      background-color: #cecece;
      background-image: var(--picture);
      background-size: cover;
      background-position: center;
    }
  }
  &-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 1.1;
    flex: 1;
    padding: $mpadding/2 0;
    min-height: $avata_size + $mpadding;
    .name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .the_icon {
        margin: 0 $mpadding/2;
        opacity: 0.3;
        font-size: 150%;
        &.isSelected {
          color: $primary_color;
          opacity: 1;
        }
      }
    }
    .button {
      cursor: pointer;
      &:active {
        opacity: 0.5;
      }
    }
  }
  &:not(:first-child) {
    margin: $mpadding/2 0 0 0;
  }
}

@include ItermitColor(#ffcdcd, #fff);
</style>
